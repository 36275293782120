import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
// import BaseFunctions from '@/components/Utility/Base'
import { Menus } from '@/services/MenusDataService'
import DomainsDataService from '@/services/DomainsDataService'
import { Posts } from '@/services/PostsDataService'
import SysPost, { SysPostCategory, SysPostVersion } from '@/types/SysPost'
import SysMenu, { MenuTypePlacementFriendlyType, SysMenuMenuType } from '@/types/SysMenu'
import CommonFunctions, { staticPages } from '@/components/Utility/Common'

/* export default defineComponent({
  name: 'AdminMenus',
  setup () {
    // component setup
  }
}) */

type menuDataType = { menuTitle: string; menuURL: string; menuSubmenu: boolean; menuSubMenuId: number; menuPosition: number; menuType: number }
type menuTypeDataType = { menuTypeTitel: string }
type dataReturnType = { allMenus: any; menus: any; filterStatusStringValue: string; menuSearchInputValue: string; menuTypeTitleOptions: any[]; menuTypeTitleFilterOptions: any[]; menuTypeDomainTitleOptions: any[]; indlaegs: any; indlaegversionering: any; indlaegkategori: any; totalPages: number; tabValue: string; index: number; error: any; }

@Options({
  props: {
    msg: String
  }
})
export default class AdminMenus extends Vue {
  msg!: string
  error: any = null
  private tabValue = 'Vis og rediger menupunkter'
  private indlaegs: SysPost[] = []
  private indlaegversionerings: SysPostVersion[] = []
  private indlaegkategoris: SysPostCategory[] = []
  private indlaegversionering = {} as SysPostVersion
  private indlaegkategori = {} as SysPostCategory
  private menus: SysMenu[] = []
  private allMenus: SysMenu[] = []
  private menutypes: SysMenuMenuType[] = []
  private tempMenu = {} as SysMenu
  private tempMenuType = {} as SysMenuMenuType
  private tempPost = {} as SysPost
  menuData: menuDataType = { menuTitle: '', menuURL: '', menuSubmenu: false, menuSubMenuId: 0, menuPosition: 0, menuType: 0 }
  menuTypeData: menuTypeDataType = { menuTypeTitel: '' }
  filterByPostTypeValue = { text: 'Sider', value: '1' }
  filterByPostTypeOptions: { text: string; value: string }[] = [{ text: 'Sider', value: '1' }, { text: 'Indlæg', value: '0' }, { text: 'Statiske sider', value: '2' }]
  postTypeFilterTerm = 'indlaeg_type=1'
  filterStatusStringValue = 'Vis alt'
  filterStatusStringOptions: string[] = ['Vis alt', 'Aktiv', 'Inaktiv']
  menuTypeTitleFilterValue = { text: 'Vælg her', value: '0' }
  menuTypeTitleFilterOptions: {text: string; value: string }[] = [{ text: 'Vælg her', value: '0' }]
  menuTypeFilterTerm = ''
  menuDomainFilterValue = { text: 'Vælg her', value: '0' }
  menuDomainFilterOptions: { text: string; value: string }[] = [{ text: 'Vælg her', value: '0' }, { text: 'DDU', value: '1' }, { text: 'Dartens Dag', value: '2' }, { text: 'SpilDart.nu', value: '3' }]
  domainFilterTerm = ''
  menuTypeDomainFilterTerm = ''
  menuTypeTitleValue = { text: 'Vælg her', value: '0' }
  menuTypeTitleOptions: { text: string; value: string }[] = [{ text: 'Vælg her', value: '0' }]
  menuSubMenuTitleValue = { text: 'Vælg her', value: '-1' }
  menuSubMenuTitleOptions: { text: string; value: string }[] = [{ text: 'Vælg her', value: '-1' }, { text: '-- RODDEN --', value: '0' }]
  menuTypePlacementValue = 'Vælg her'
  menuTypePlacementOptions = ['Vælg her', MenuTypePlacementFriendlyType.Top, MenuTypePlacementFriendlyType.Bund, MenuTypePlacementFriendlyType.Sidebar, MenuTypePlacementFriendlyType.Topbar]
  menuTypeDomainTitleValue = { text: 'Vælg her', value: '0' }
  menuTypeDomainTitleOptions: { text: string; value: string }[] = [{ text: 'Vælg her', value: '0' }]
  menuURLValue = { text: '', value: '0', slug: '' }
  menuURLOptions: { text: string; value: string; type: string; slug: string }[] = []
  menuSearchInputValue = ''
  menuSearchTerm = ''
  toggleIconsSortingValue = 0
  private oldSort = ''
  private oldSort2 = ''
  private submitted = false
  private submitted2 = false
  private postSortingOrder = ''
  private currentListSortingOrder = 'menu_type_id:asc,menu_submenu:asc,menu_submenu_id:asc'
  private editMenuModal = false
  private editMenuTypeModal = false
  private deleteMenuWarningModal = false
  private deleteMenuTypeWarningModal = false
  private deleteMenuTypeId = 0
  private deleteMenuId = 0
  private lastEditedMenuId = 0
  private lastEditedMenuTypeId = 0
  private lastCreatedMenuId = 0
  private lastCreatedMenuTypeId = 0
  private domainInputFieldClickCount = 0
  private URLInputFieldClickCount = 0
  private index = 0
  extraParameter = ''
  extraParameter2 = ''
  // menuURLOptions: string[] = []
  compareMenuPlacement = ''
  private currentPage = 1
  private totalPages = 0
  private totalNumberOfPages = 0
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']

  readonly name : string = 'AdminMenus'
  $Message: any

  data (): dataReturnType {
    return {
      allMenus: this.allMenus,
      menus: this.menus,
      filterStatusStringValue: this.filterStatusStringValue,
      menuSearchInputValue: this.menuSearchInputValue,
      menuTypeTitleOptions: this.menuTypeTitleOptions,
      menuTypeTitleFilterOptions: this.menuTypeTitleFilterOptions,
      menuTypeDomainTitleOptions: this.menuTypeDomainTitleOptions,
      indlaegs: this.indlaegs,
      indlaegversionering: this.indlaegversionering,
      indlaegkategori: this.indlaegkategori,
      totalPages: this.totalPages,
      tabValue: 'Vis og rediger menupunkter',
      index: this.index,
      error: this.error
    }
  }

  @Watch('deleteMenuWarningModal')
  onMenuWarningModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteMenuId = 0
    }
  }

  @Watch('deleteMenuTypeWarningModal')
  onMenuTypeWarningModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteMenuTypeId = 0
    }
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrieveMenus()
  }

  @Watch('filterStatusStringValue')
  onMenuStatusChanged (statusValue: string, oldStatusValue: string) : void {
    // console.log('Filter status changed to: ' + statusValue)

    this.retrieveMenus()
  }

  @Watch('menuTypeTitleValue')
  onMenuTypeChange (menuTypeTitleValue: string) : void {
    // console.log('Change Menu type to ' + this.menuTypeTitleValue.value)

    // this.fillSubMenuTitleOptionsData()
    this.fillSubMenuTitleOptionsDataNew()
  }

  @Watch('menuTypeTitleFilterValue')
  onMenuTypeFilterChange (newTypeTitleValue : string) : void {
    // console.log(newTypeTitleValue)

    if (this.menuTypeTitleFilterValue.value === '0') {
      this.menuTypeFilterTerm = ''
    } else {
      this.menuTypeFilterTerm = 'menu_type_id=' + this.menuTypeTitleFilterValue.value
    }

    this.retrieveMenus()
  }

  @Watch('filterByPostTypeValue')
  onPostTypeChange (newPostVal: { text: string, value: string }) : void {
    this.menuURLOptions = []
    // console.log(newPostVal)

    if (newPostVal.value === '2') {
      this.menuURLOptions = staticPages
      this.menuURLOptions.sort(function (a, b) {
        return a.text.toLowerCase().localeCompare(b.text.toLowerCase())
      })
    } else {
      this.postTypeFilterTerm = 'indlaeg_type=' + this.filterByPostTypeValue.value
      this.retrievePosts()
    }
  }

  @Watch('menuDomainFilterValue')
  onDomainChange (newDomainVal: string) : void {
    this.menuTypeTitleFilterValue = { text: 'Vælg her', value: '0' }
    // console.log(newDomainVal)

    if (this.menuDomainFilterValue.value === '0') {
      this.domainFilterTerm = ''
      this.menuTypeDomainFilterTerm = ''
    } else {
      this.domainFilterTerm = 'menu_type_id.domaene_id.id=' + this.menuDomainFilterValue.value
      this.menuTypeDomainFilterTerm = 'domaene_id.id=' + this.menuDomainFilterValue.value
    }

    this.retrieveMenus()
    this.retrieveMenuTypes()
  }

  // @Watch('toggleIconsSortingValue')
  // onListSortToggleChanged (toggleValue: string, oldToggleValue: string) : void {
  //   console.log('List sort toggle changed to: ' + toggleValue)
  //   if (toggleValue === 'check_box_outline_blank') {
  //     this.currentListSortingOrder = ''
  //   } else if (toggleValue === 'badge') {
  //     this.currentListSortingOrder = 'menu_titel:asc,menu_type_id.menu_type_titel:asc'
  //   } else if (toggleValue === 'contact_mail') {
  //     this.currentListSortingOrder = 'menu_url:asc'
  //   } else if (toggleValue === 'login') {
  //     this.currentListSortingOrder = 'menu_type_id.menu_type_placering:asc'
  //   } else if (toggleValue === 'how_to_reg') {
  //     this.currentListSortingOrder = 'updated_at:desc'
  //   }

  //   this.retrieveMenus()
  // }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage >= this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveMenus()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveMenus()
  }

  public columnSort2 (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = ''
      this.oldSort2 = ''
    }
    if (sortBy === 'titel') {
      if (this.oldSort2 === '' || this.oldSort2 !== sortBy) {
        this.currentListSortingOrder = 'menu_type_titel:asc'
        this.oldSort2 = sortBy
      } else {
        this.currentListSortingOrder = 'menu_type_titel:desc'
        this.oldSort2 = ''
      }
    }
    if (sortBy === 'placering') {
      if (this.oldSort2 === '' || this.oldSort2 !== sortBy) {
        this.currentListSortingOrder = 'menu_type_placering:asc'
        this.oldSort2 = sortBy
      } else {
        this.currentListSortingOrder = 'menu_type_placering:desc'
        this.oldSort2 = ''
      }
    }

    this.retrieveMenuTypes()
  }

  public columnSort (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = 'menu_type_id:asc,menu_submenu:asc,menu_submenu_id:asc'
      this.oldSort = ''
      this.menuSearchTerm = ''
      this.menuSearchInputValue = ''
      this.filterStatusStringValue = 'Vis alt'
      this.currentPage = 1
      this.menuTypeTitleFilterValue = { text: 'Vælg her', value: '0' }
      this.menuDomainFilterValue = { text: 'Vælg her', value: '0' }
    }
    if (sortBy === 'title') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'menu_titel:asc,menu_type_id.menu_type_titel:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'menu_titel:desc,menu_type_id.menu_type_titel:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'domain') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'menu_url:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'menu_url:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'submenu') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'menu_submenu_id:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'menu_submenu_id:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'menutype') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'menu_type_id.menu_type_titel:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'menu_type_id.menu_type_titel:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'placering') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'menu_type_id.menu_type_placering:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'menu_type_id.menu_type_placering:desc'
        this.oldSort = ''
      }
    }

    this.retrieveMenus()
  }

  @Watch('editMenuModal')
  onEditMenuModalToggleChanged (toggleValue: boolean, oldToggleValue: boolean) : void {
    if (toggleValue === false) {
      this.menuData = { menuTitle: '', menuURL: '', menuSubmenu: false, menuSubMenuId: 0, menuPosition: 0, menuType: 0 }
      this.menuTypeTitleValue = { text: 'Vælg her', value: '0' }
      this.menuSubMenuTitleValue = { text: 'Vælg her', value: '-1' }
      this.menuURLValue = { text: '', value: '0', slug: '' }
    }
  }

  @Watch('editMenuTypeModal')
  onEditMenuTypeModalToggleChanged (toggleValue: boolean, oldToggleValue: boolean) : void {
    if (toggleValue === false) {
      this.menuTypeData = { menuTypeTitel: '' }
      this.menuTypePlacementValue = 'Vælg her'
      this.menuTypeDomainTitleValue = { text: 'Vælg her', value: '0' }
    }
  }

  public getFQDNOfURL (urlVal: string) : string {
    if (urlVal.trim().toLowerCase().startsWith('http://') || urlVal.trim().toLowerCase().startsWith('https://')) {
      return new URL(urlVal).hostname
    }

    return urlVal.length === 0 ? '-' : '* SITE INTERN SLUG *'
  }

  public getFQDNOfDomainId (menuTypeId: number) {
    let retVal = '-'
    const menutypesIndex = this.menutypes.findIndex(x => x.id === menuTypeId)

    if (menutypesIndex !== undefined && menutypesIndex >= 0 && this.menutypes[menutypesIndex].domaene_id !== undefined &&
      this.menutypes[menutypesIndex].domaene_id !== null &&
      this.menutypes[menutypesIndex].domaene_id.domaene_titel !== null &&
      this.menutypes[menutypesIndex].domaene_id.domaene_url !== null) {
      retVal = this.menutypes[menutypesIndex].domaene_id.domaene_url + ' [' + this.menutypes[menutypesIndex].domaene_id.domaene_titel + ']'
    }

    return retVal
  }

  public getIndexOfMenuFromId (menuId: number) : number {
    const retVal = this.allMenus.findIndex(x => x.id === menuId)
    // console.log('retVal = ' + retVal)

    return (retVal === undefined ? -1 : retVal)
  }

  public getIndexOfPostVersion (postVersion: number, singlePostEntryObj: SysPost = this.tempPost) : number {
    const retVal = singlePostEntryObj.indlaeg_versionerings_id.findIndex(x => x.indlaeg_versionering_version === postVersion)
    // console.log('Index of post version = ' + retVal + ' ; post versioning object = ' + JSON.stringify(singlePostEntryObj.indlaeg_versionerings_id))

    return (retVal === undefined ? -1 : retVal)
  }

  public fillMenuURLOptionsData () : void {
    this.menuURLOptions = []
    for (const indlaeg of this.indlaegs) {
      if (indlaeg.indlaeg_status === true) {
        // this.menuURLOptions.push(indlaeg.indlaeg_versionerings_id[this.getIndexOfPostVersion(indlaeg.indlaeg_version, indlaeg)].indlaeg_versionering_titel)
        this.menuURLOptions.push({ text: indlaeg.indlaeg_versionerings_id[this.getIndexOfPostVersion(indlaeg.indlaeg_version, indlaeg)].indlaeg_versionering_titel, value: Number(indlaeg.indlaeg_versionerings_id[this.getIndexOfPostVersion(indlaeg.indlaeg_version, indlaeg)].id).toString(), type: Number(indlaeg.indlaeg_type).toString(), slug: indlaeg.indlaeg_slug })
      }
    }

    // console.log('[fillMenuURLOptionsData] menuURLOptions = ' + JSON.stringify(this.menuURLOptions))
  }

  // Fill the menu title options array with data of the available submenu items
  public fillSubMenuTitleOptionsData (excludeMenuId = 0) : void {
    this.menuSubMenuTitleOptions = [{ text: 'Vælg her', value: '-1' }, { text: '-- RODDEN --', value: '0' }]
    for (const menuitem of this.menus) {
      if (menuitem.menu_submenu) {
        if (excludeMenuId === 0 || (excludeMenuId > 0 && Number(menuitem.id) !== excludeMenuId)) {
          this.menuSubMenuTitleOptions.push({ text: menuitem.menu_titel, value: (menuitem.id === undefined ? '0' : Number(menuitem.id).toString()) })
        }
      }
      // console.log('Menu titles = ' + JSON.stringify(this.menuSubMenuTitleOptions))
    }
  }

  // Fill the menu title options array with data of the available submenu items
  public fillSubMenuTitleOptionsDataNew (excludeMenuId = 0) : void {
    this.menuSubMenuTitleOptions = [{ text: 'Vælg her', value: '-1' }, { text: '-- RODDEN --', value: '0' }]
    if (excludeMenuId === 0) {
      for (const menuitem of this.allMenus) {
        if (menuitem.menu_status === true && menuitem.menu_submenu !== true && Number(this.menuTypeTitleValue.value) === menuitem.menu_type_id.id) {
          this.menuSubMenuTitleOptions.push({ text: menuitem.menu_titel, value: (menuitem.id === undefined ? '0' : Number(menuitem.id).toString()) })
          for (const submenuitem of this.menus) {
            if (menuitem.id === submenuitem.menu_submenu_id && submenuitem.menu_submenu === true && submenuitem.menu_status === true) {
              this.menuSubMenuTitleOptions.push({ text: '-- ' + submenuitem.menu_titel, value: (submenuitem.id === undefined ? '0' : Number(submenuitem.id).toString()) })
            }
          }
          // console.log('Menu type id ' + this.menuTypeTitleValue.value)
        }
        // if (menuitem.menu_status === true && menuitem.menu_submenu === true) {
        //   this.menuSubMenuTitleOptions.push({ text: '-- ' + menuitem.menu_titel, value: (menuitem.id === undefined ? '0' : Number(menuitem.id).toString()) })
        // }
      }
    }
    if (excludeMenuId !== 0) {
      for (const menuitem of this.allMenus) {
        if (menuitem.id !== excludeMenuId && menuitem.menu_status === true && menuitem.menu_submenu !== true && Number(this.menuTypeTitleValue.value) === menuitem.menu_type_id.id) {
          this.menuSubMenuTitleOptions.push({ text: menuitem.menu_titel, value: (menuitem.id === undefined ? '0' : Number(menuitem.id).toString()) })
          for (const submenuitem of this.allMenus) {
            if (submenuitem.id !== excludeMenuId && menuitem.id === submenuitem.menu_submenu_id && submenuitem.menu_submenu === true && submenuitem.menu_status === true) {
              this.menuSubMenuTitleOptions.push({ text: '-- ' + submenuitem.menu_titel, value: (submenuitem.id === undefined ? '0' : Number(submenuitem.id).toString()) })
            }
          }
        }
      }
    }
  }

  public forceRerenderMenusList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public findMenu () : void {
    // console.log('Search menu.')
    this.menuSearchTerm = this.menuSearchInputValue
    this.retrieveMenus()
  }

  private templateGetDomains (event: any, resetCountFlag: false) {
    // console.log('templateGetDomains event name = ' + JSON.stringify(event.target.tagName))
    if (resetCountFlag === false || event.target.tagName === 'I' || event.target.tagName === 'INPUT') {
      this.domainInputFieldClickCount++
      // console.log('domainInputFieldClickCount = ' + this.domainInputFieldClickCount)
    } else {
      this.domainInputFieldClickCount = 1
    }

    this.retrieveDomains()
  }

  private templateGetPostPageURLs (event: any, resetCountFlag: false) {
    // console.log('templateGetPostPageURLs event name = ' + JSON.stringify(event.target.tagName))
    if (resetCountFlag === false || event.target.tagName === 'I' || event.target.tagName === 'INPUT') {
      this.URLInputFieldClickCount++
      // console.log('URLInputFieldClickCount = ' + this.URLInputFieldClickCount)
    } else {
      this.URLInputFieldClickCount = 1
    }

    this.retrievePosts()
  }

  public editMenu (menuId : number) : void {
    // console.log('Edit menu item with Id: ' + menuId)
    this.lastEditedMenuId = menuId

    Menus.MenusDataService.get(menuId.toString())
      .then((response) => {
        this.tempMenu = response.data
        // console.log(response.data)

        this.menuData = { menuTitle: this.tempMenu.menu_titel, menuURL: this.tempMenu.menu_url, menuSubmenu: this.tempMenu.menu_submenu, menuSubMenuId: this.tempMenu.menu_submenu_id, menuPosition: this.tempMenu.menu_position, menuType: Number(this.tempMenu.menu_type_id.id) }

        // this.fillSubMenuTitleOptionsData(Number(this.tempMenu.id))

        if (this.tempMenu.menu_type_id === undefined || this.tempMenu.menu_type_id.id === null) {
          this.menuTypeTitleValue = { text: 'Vælg her', value: '0' }
        } else if (this.tempMenu.menu_type_id.id === 9) {
          this.menuTypeTitleValue = { text: 'Ukendt menugruppe', value: '9' }
        } else {
          this.menuTypeTitleValue = { text: this.tempMenu.menu_type_id.menu_type_titel, value: (this.tempMenu.menu_type_id.id === undefined ? '0' : Number(this.tempMenu.menu_type_id.id).toString()) }
        }
        this.fillSubMenuTitleOptionsDataNew(Number(this.tempMenu.id)) // Note: The menuTypeTitleValue object must have correct data when calling this method

        if (this.tempMenu.menu_submenu_id === undefined || this.tempMenu.menu_submenu_id === null || this.tempMenu.menu_submenu_id < 0) {
          this.menuSubMenuTitleValue = { text: 'Vælg her', value: '-1' }
        } else if (this.tempMenu.menu_submenu_id === 0) {
          this.menuSubMenuTitleValue = { text: '-- RODDEN --', value: '0' }
        } else {
          const indexOfSubMenu = this.getIndexOfMenuFromId(this.tempMenu.menu_submenu_id)
          if (indexOfSubMenu >= 0) {
            this.menuSubMenuTitleValue = { text: this.allMenus[indexOfSubMenu].menu_titel, value: Number(this.tempMenu.menu_submenu_id).toString() }
          }
          /* for (const menuTitleValue of this.menus) {
            // this.menuSubMenuTitleValue = { text: menuTitleValue.menu_titel, value: Number(this.tempMenu.menu_submenu_id).toString() }
            // Matcher undermenu med den relevante hovedmenu. Således at begge menupunkter skal tilhøre den samme menu
            // Virker men mangler, at sætte en Watch, der ændringer i Menugruppen
            if (this.tempMenu.menu_submenu_id === menuTitleValue.id) {
              this.menuSubMenuTitleValue = { text: menuTitleValue.menu_titel, value: Number(this.tempMenu.menu_submenu_id).toString() }
            }
          } */
        }

        this.menuURLValue = { text: this.tempMenu.menu_url, value: '0', slug: this.tempMenu.menu_url }
        this.retrievePosts(true)
      })
      .catch((err) => {
        this.error = err
        this.editMenuModal = false
        // this.fillSubMenuTitleOptionsData()
        this.fillSubMenuTitleOptionsDataNew()
      })
  }

  public cancelMenuEdit () : void {
    // console.log('Cancel menu item edit.')

    this.menuData = { menuTitle: '', menuURL: '', menuSubmenu: false, menuSubMenuId: 0, menuPosition: 0, menuType: 0 }
    this.menuTypeTitleValue = { text: 'Vælg her', value: '0' }
    this.menuSubMenuTitleValue = { text: 'Vælg her', value: '-1' }
    this.menuURLValue = { text: '', value: '0', slug: '' }
    // this.fillSubMenuTitleOptionsData()
    this.fillSubMenuTitleOptionsDataNew()
  }

  public statusMenu (menuId : number) : void {
    // console.log('Change status of menu item with Id: ' + menuId)

    Menus.MenusDataService.get(menuId.toString())
      .then((response) => {
        this.tempMenu = response.data
        // console.log(response.data)

        const updateMenuData = {
          menu_status: !this.tempMenu.menu_status
        }

        // console.log('New status of menu item = ' + updateMenuData.menu_status)

        Menus.MenusDataService.update(menuId.toString(), updateMenuData)
          .then((response) => {
            // this.lastUpdatedMenuId = response.data.id
            // console.log(response.data)

            const menusIndexToUpdateStatus = this.menus.findIndex(x => x.id === menuId)
            this.menus[menusIndexToUpdateStatus].menu_status = updateMenuData.menu_status
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deleteMenuWarning (menuId: number) : void {
    this.deleteMenuId = menuId
    this.deleteMenuWarningModal = true
  }

  public deleteMenu (menuId : number) : void {
    let tempMenus: SysMenu[] = []
    // First: If the menu to be deleted have subMenus associated with it change them to have menu_submenu_id = 0, menu_submenu = false, and menu_status = false
    console.log('Reassigning menus with menu_submenu_id = ' + menuId)
    Menus.MenusDataService.getAll('', null, `menu_submenu_id=${menuId.toString()}`)
      .then((response) => {
        tempMenus = response.data

        if (tempMenus.length > 0) {
          for (const item of tempMenus) {
            const updateMenuData = {
              menu_submenu_id: 0,
              menu_submenu: false,
              menu_status: false
            }

            Menus.MenusDataService.update(Number(item.id).toString(), updateMenuData)
              .then((response) => {
                // console.log(response.data)
              })
              .catch((err) => {
                this.error = err
              })
          }
        }
      })
      .catch((err) => {
        this.error = err
      })
      .then(() => {
        // console.log('Delete menu item with Id: ' + menuId)
        Menus.MenusDataService.delete(menuId.toString())
          .then((response) => {
            // console.log(response.data)

            this.retrieveMenus()
            this.deleteMenuWarningModal = false
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
  }

  public editMenuType (menuTypeId: number) : void {
    // console.log('Edit menu type with Id: ' + menuTypeId)
    this.lastEditedMenuTypeId = menuTypeId

    Menus.MenuTypesDataService.get(menuTypeId.toString())
      .then((response) => {
        this.tempMenuType = response.data
        // console.log(response.data)

        this.menuTypeData = { menuTypeTitel: this.tempMenuType.menu_type_titel }
        if (this.tempMenuType === undefined || this.tempMenuType.id === null || this.tempMenuType.menu_type_placering === null) {
          this.menuTypePlacementValue = 'Vælg her'
        } else {
          this.menuTypePlacementValue = this.tempMenuType.menu_type_placering.toString()
        }

        this.retrieveDomains(true)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public cancelMenuTypeEdit () : void {
    // console.log('Cancel menu type edit.')
    this.menuTypeData = { menuTypeTitel: '' }
    this.menuTypePlacementValue = 'Vælg her'
    this.menuTypeDomainTitleValue = { text: 'Vælg her', value: '0' }
  }

  public statusMenuType (menuTypeId: number) : void {
    // console.log('Change status of menu type with Id: ' + menuTypeId)

    Menus.MenuTypesDataService.get(menuTypeId.toString())
      .then((response) => {
        this.tempMenuType = response.data
        // console.log(response.data)

        const updateMenuTypeData = {
          menu_type_status: !this.tempMenuType.menu_type_status
        }

        console.log('New status of menu type = ' + updateMenuTypeData.menu_type_status)

        Menus.MenuTypesDataService.update(menuTypeId.toString(), updateMenuTypeData)
          .then((response) => {
            // this.lastUpdatedMenuId = response.data.id
            // console.log(response.data)

            const menuTypesIndexToUpdateStatus = this.menutypes.findIndex(x => x.id === menuTypeId)
            this.menutypes[menuTypesIndexToUpdateStatus].menu_type_status = updateMenuTypeData.menu_type_status
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deleteMenuTypeWarning (menuTypeId: number) : void {
    this.deleteMenuTypeId = menuTypeId
    this.deleteMenuTypeWarningModal = true
  }

  public deleteMenuType (menuTypeId: number) : void {
    let tempMenus: SysMenu[] = []

    if (menuTypeId !== 9) { // Ensures that menuType width the id 9, cannot be deleted
      // console.log('Fetching all menus with the menuTypid of ' + menuTypeId + ' and reassigning them to menuType id 9')

      Menus.MenusDataService.getAll('', null, `menu_type_id.id=${menuTypeId.toString()}`)
        .then((response) => {
          tempMenus = response.data

          if (tempMenus.length > 0) {
            for (const item of tempMenus) {
              const updateMenusData = {
                menu_type_id: 9
              }

              Menus.MenusDataService.update(Number(item.id).toString(), updateMenusData)
                .then((response) => {
                  console.log(response.data)
                })
                .catch((err) => {
                  this.error = err
                })
            }
          }
        })
        .catch((err) => {
          this.error = err
        })
        .then(() => {
          // console.log('Delete menu type with Id: ' + menuTypeId)
          Menus.MenuTypesDataService.delete(menuTypeId.toString())
            .then((response) => {
              // console.log(response.data)

              this.retrieveMenuTypes()
              this.retrieveMenus()
              this.deleteMenuTypeWarningModal = false
            })
            .catch((err) => {
              this.error = err
              // console.log(err)
            })
        })
    }
  }

  public addNewOption (newOption: string) : void {
    const maxId = Math.max(...this.menuURLOptions.map(obj => { return Number(obj.value) }))
    const option = {
      text: newOption,
      value: (maxId + 1).toString(),
      type: '0',
      slug: CommonFunctions.slugify(newOption)
    }
    this.menuURLOptions.push(option)
  }

  public createMenu () : void {
    // Handel the situation where there is created a new text in the option selection by the user (which sets the value object to a simple text string).
    // const tempMenuURL = this.menuURLValue.value === undefined ? JSON.stringify(this.menuURLValue).replace(/^"(.*)"$/, '$1').trim() : this.menuURLValue.slug.toString().trim()
    // const tempMenuURL = this.menuURLValue.value === undefined ? JSON.stringify(this.menuURLValue).trim() : this.menuURLValue.slug.toString().trim()
    const tempMenuURL = this.menuURLValue.text.trim().includes('http') || this.menuURLValue.text.trim().includes('www') ? this.menuURLValue.text : this.menuURLValue.slug

    if (this.menuData.menuTitle.length > 1 && tempMenuURL.length > 0 &&
      this.menuTypeTitleValue.value !== '0') {
      const createMenuData = {
        menu_status: true,
        menu_titel: this.menuData.menuTitle,
        menu_url: tempMenuURL,
        menu_submenu: this.menuData.menuSubmenu,
        menu_submenu_id: this.menuSubMenuTitleValue.value,
        menu_position: this.menuData.menuPosition,
        menu_type_id: this.menuTypeTitleValue.value
      }

      // console.log('Attempt to create new menu item with Id: ' + (1 + this.lastCreatedMenuId))

      Menus.MenusDataService.create(createMenuData)
        .then((response) => {
          this.lastCreatedMenuId = response.data.id
          // console.log(response.data)
          this.submitted = true
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
        .then(() => {
          this.$Message.success({ text: 'Menupunktet er blevet oprettet' })
          this.newCreateMenu()
          this.tabValue = 'Vis og rediger menupunkter'
        })

      // this.menuData = { menuTitle: '', menuURL: '', menuSubmenu: false, menuSubMenuId: 0, menuPosition: 0, menuType: 0 }
      // this.menuTypeTitleValue = { text: 'Vælg her', value: '0' }
      // this.menuSubMenuTitleValue = { text: 'Vælg her', value: '-1' }
      // this.menuURLValue = { text: '', value: '0', slug: '' }
    }
  }

  public newCreateMenu () : void {
    this.submitted = false
    this.menuData = { menuTitle: '', menuURL: '', menuSubmenu: false, menuSubMenuId: 0, menuPosition: 0, menuType: 0 }
    this.menuTypeTitleValue = { text: 'Vælg her', value: '0' }
    this.menuSubMenuTitleValue = { text: 'Vælg her', value: '-1' }
    this.menuURLValue = { text: '', value: '0', slug: '' }
    this.retrieveMenus()
    this.retrieveAllMenus()
  }

  public createMenuType () : void {
    if (this.menuTypeData.menuTypeTitel.length > 1 && this.menuTypePlacementValue !== 'Vælg her') {
      const createMenuTypeData = {
        menu_type_titel: this.menuTypeData.menuTypeTitel,
        menu_type_placering: this.menuTypePlacementValue,
        domaene_id: (this.menuTypeDomainTitleValue.text === 'Vælg her' ? null : this.menuTypeDomainTitleValue.value)
      }

      console.log('Attempt to create new menu type with Id: ' + (1 + this.lastCreatedMenuTypeId))

      Menus.MenuTypesDataService.create(createMenuTypeData)
        .then((response) => {
          this.lastCreatedMenuTypeId = response.data.id
          // console.log(response.data)
          this.submitted2 = true
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
        .then(() => {
          this.$Message.success({ text: 'Menugruppen er blevet oprettet' })
          this.newCreateMenuType()
          this.tabValue = 'Vis og rediger menugrupper'
        })

      this.menuTypeData = { menuTypeTitel: '' }
      this.menuTypePlacementValue = 'Vælg her'
    }
  }

  public newCreateMenuType () : void {
    this.submitted2 = false
    this.menuTypeData = { menuTypeTitel: '' }
    this.menuTypePlacementValue = 'Vælg her'
    this.menuTypeDomainTitleValue = { text: 'Vælg her', value: '0' }
    this.retrieveMenuTypes()
    this.retrieveMenus()
  }

  public updateMenu () : boolean {
    // console.log('Update information of menu item with Id: ' + this.lastEditedMenuId)
    // console.log('menuURLValue = ' + JSON.stringify(this.menuURLValue))
    // Handel the situation where there is created a new text in the option selection by the user (which sets the value object to a simple text string).
    // const tempMenuURL = this.menuURLValue.value === undefined ? JSON.stringify(this.menuURLValue).replace(/^"(.*)"$/, '$1').trim() : this.menuURLValue.slug.toString().trim()
    // const tempMenuURL = this.menuURLValue.value === undefined ? JSON.stringify(this.menuURLValue).trim() : this.menuURLValue.slug.toString().trim()
    const tempMenuURL = this.menuURLValue.text.trim().includes('http') || this.menuURLValue.text.trim().includes('www') ? this.menuURLValue.text : this.menuURLValue.slug
    // console.log(tempMenuURL)
    // console.log('menuTitel: ' + this.menuData.menuTitle.length)
    // console.log('MenuUrl: ' + tempMenuURL)
    // console.log('Menuposition: ' + this.menuData.menuPosition)
    // console.log('Menugruppe titel: ' + this.menuTypeTitleValue.value)
    // console.log('SubmenuTitleValue: ' + this.menuSubMenuTitleValue.value)

    if (this.menuData.menuTitle.length > 1 && tempMenuURL.length > 0 && Number(this.menuData.menuPosition) >= 0 &&
      this.menuTypeTitleValue.value !== '0' && (this.menuData.menuSubmenu === false || this.menuSubMenuTitleValue.value !== '-1')) {
      const updateMenuData = {
        menu_titel: this.menuData.menuTitle,
        menu_url: tempMenuURL,
        menu_submenu: this.menuData.menuSubmenu,
        menu_submenu_id: this.menuSubMenuTitleValue.value,
        menu_position: this.menuData.menuPosition,
        menu_type_id: this.menuTypeTitleValue.value
      }

      // console.log('updateMenuData = ' + JSON.stringify(updateMenuData))

      Menus.MenusDataService.update(this.lastEditedMenuId.toString(), updateMenuData)
        .then((response) => {
          // this.lastUpdateMenuId = response.data.id
          // console.log(response.data)
          this.retrieveMenus()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })

      this.editMenuModal = false
      this.menuData = { menuTitle: '', menuURL: '', menuSubmenu: false, menuSubMenuId: 0, menuPosition: 0, menuType: 0 }
      this.menuTypeTitleValue = { text: 'Vælg her', value: '0' }
      this.menuSubMenuTitleValue = { text: 'Vælg her', value: '-1' }
      this.menuURLValue = { text: '', value: '0', slug: '' }

      return true
    }

    return false
  }

  public updateMenuType () : boolean {
    // console.log('Update information of menu type with Id: ' + this.lastEditedMenuTypeId)

    if (this.menuTypeData.menuTypeTitel.length > 1 && this.menuTypePlacementValue !== 'Vælg her') {
      const updateMenuTypeData = {
        menu_type_titel: this.menuTypeData.menuTypeTitel,
        menu_type_placering: this.menuTypePlacementValue,
        domaene_id: (this.menuTypeDomainTitleValue.text === 'Vælg her' ? null : this.menuTypeDomainTitleValue.value)
      }

      Menus.MenuTypesDataService.update(this.lastEditedMenuTypeId.toString(), updateMenuTypeData)
        .then((response) => {
          // this.lastUpdateMenuTypeId = response.data.id
          // console.log(response.data)
          this.retrieveMenuTypes()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })

      this.editMenuTypeModal = false
      this.menuTypeData = { menuTypeTitel: '' }
      this.menuTypeDomainTitleValue = { text: 'Vælg her', value: '0' }
      this.menuTypePlacementValue = 'Vælg her'

      return true
    }

    return false
  }

  public retrieveDomains (activateEditMenuTypeModalFlag: boolean | null = null) : void {
    if (activateEditMenuTypeModalFlag !== null || (this.domainInputFieldClickCount & 1) === 1) {
      // console.log('Get domains data from API.')

      DomainsDataService.findByDomainStatus(true)
        .then((response) => {
          const tempDomains = response.data
          // console.log(response.data)

          // Fill the domain options array of the menu type
          this.menuTypeDomainTitleOptions = [{ text: 'Vælg her', value: '0' }]
          for (const tempDomain of tempDomains) {
            this.menuTypeDomainTitleOptions.push({ text: tempDomain.domaene_titel, value: (tempDomain.id === undefined ? '0' : Number(tempDomain.id).toString()) })

            // Set selection value to the value of the currently set domain
            if (tempDomain.id !== undefined && this.tempMenuType.domaene_id !== undefined && this.tempMenuType.domaene_id !== null && Number(this.tempMenuType.domaene_id.id) === Number(tempDomain.id)) {
              this.menuTypeDomainTitleValue = { text: tempDomain.domaene_titel, value: Number(tempDomain.id).toString() }
            }
          }
          // console.log('Menu type domain titles = ' + JSON.stringify(this.menuTypeDomainTitleOptions))

          // Set the edit dialog modal variable according to the value given as a function flag argument, if the value of the flag argument is different from null and has a valid boolean value.
          if (activateEditMenuTypeModalFlag !== null && (activateEditMenuTypeModalFlag === true || activateEditMenuTypeModalFlag === false)) {
            this.editMenuTypeModal = activateEditMenuTypeModalFlag
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public retrievePosts (activateEditMenuModalFlag: boolean | null = null) : void {
    if (activateEditMenuModalFlag !== null || (this.URLInputFieldClickCount & 1) === 1) {
      console.log('Fetching post data from API.')

      Posts.PostsDataService.getAll('', null, `indlaeg_status=true&${this.postTypeFilterTerm}`)
        .then((response) => {
          this.indlaegs = response.data
          // console.log(response.data)

          this.fillMenuURLOptionsData()

          // Set the edit dialog modal variable according to the value given as a function flag argument, if the value of the flag argument is different from null and has a valid boolean value.
          if (activateEditMenuModalFlag !== null && (activateEditMenuModalFlag === true || activateEditMenuModalFlag === false)) {
            this.editMenuModal = activateEditMenuModalFlag
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public retrieveMenuTypes () : void {
    // console.log('Fetching menu types data from API.')
    if (this.domainFilterTerm !== '') {
      Menus.MenuTypesDataService.getAll(this.postSortingOrder, null, this.menuTypeDomainFilterTerm)
        .then((response) => {
          this.menutypes = response.data

          this.menuTypeTitleFilterOptions = [{ text: 'Vælg her', value: '0' }]
          for (const menutype of this.menutypes) {
            this.menuTypeTitleFilterOptions.push({ text: menutype.menu_type_titel, value: (menutype.id === undefined ? '0' : Number(menutype.id).toString()) })
          }
          this.menuTypeTitleFilterOptions = this.menuTypeTitleFilterOptions.filter((element) => { return (element.value !== '9') })
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      Menus.MenuTypesDataService.getAll()
        .then((response) => {
          this.menutypes = response.data
          // console.log(response.data)

          // Fill the menu type title options array
          this.menuTypeTitleOptions = [{ text: 'Vælg her', value: '0' }]
          this.menuTypeTitleFilterOptions = [{ text: 'Vælg her', value: '0' }]
          for (const menutype of this.menutypes) {
            this.menuTypeTitleOptions.push({ text: menutype.menu_type_titel + ' - ' + (menutype.domaene_id === null ? '' : menutype.domaene_id.domaene_titel), value: (menutype.id === undefined ? '0' : Number(menutype.id).toString()) })
            this.menuTypeTitleFilterOptions.push({ text: menutype.menu_type_titel, value: (menutype.id === undefined ? '0' : Number(menutype.id).toString()) })
            // console.log('Menu type titles = ' + JSON.stringify(this.menuTypeTitleOptions))
          }
          // Filter menu type, to exclude "Ukendt menugruppe" from the array
          this.menuTypeTitleFilterOptions = this.menuTypeTitleFilterOptions.filter((element) => { return (element.value !== '9') })
          this.menuTypeTitleOptions = this.menuTypeTitleOptions.filter((element) => { return (element.value !== '9') })
          // console.log('Menutype: ' + JSON.stringify(this.menuTypeTitleOptions))
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public retrieveAllMenus () : void {
    Menus.MenusDataService.getAll()
      .then((response) => {
        this.allMenus = response.data
        this.fillSubMenuTitleOptionsDataNew()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveMenus () : void {
    // console.log('Counting Menus')
    this.editMenuModal = false
    Menus.MenusDataService.getCount(`${this.domainFilterTerm}${this.menuTypeFilterTerm}`)
      .then((response) => {
        // console.log(response.data)

        this.totalPages = response.data
        this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
      })
      .catch((err) => {
        this.error = err
      })

    // console.log('Fetching menu data from API.')

    if (this.menuSearchTerm.length > 0) {
      Menus.MenusDataService.findBySearchTerm((this.filterStatusStringValue === 'Aktiv'), (this.filterStatusStringValue === 'Vis alt'), this.menuSearchTerm, this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `${this.menuTypeFilterTerm}${this.domainFilterTerm}`)
        .then((response) => {
          this.menus = response.data
          // console.log(response.data)

          // this.fillSubMenuTitleOptionsData()
          // this.fillSubMenuTitleOptionsDataNew()
          this.forceRerenderMenusList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      if (this.filterStatusStringValue === 'Vis alt') {
        Menus.MenusDataService.getAll(this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `${this.menuTypeFilterTerm}${this.domainFilterTerm}`)
          .then((response) => {
            this.menus = response.data
            // console.log(response.data)

            // this.fillSubMenuTitleOptionsData()
            // this.fillSubMenuTitleOptionsDataNew()
            this.forceRerenderMenusList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        Menus.MenusDataService.findByMenuStatus((this.filterStatusStringValue === 'Aktiv'), this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `${this.menuTypeFilterTerm}${this.domainFilterTerm}`)
          .then((response) => {
            this.menus = response.data
            // console.log(response.data)

            // this.fillSubMenuTitleOptionsData()
            // this.fillSubMenuTitleOptionsDataNew()
            this.forceRerenderMenusList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }
    }
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.id < 100 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.role.id === 5) {
        this.retrieveMenuTypes()
        this.retrieveMenus()
        this.retrieveDomains()
        this.retrieveAllMenus()
        // this.retrievePosts()
      } else {
        CommonFunctions.redirectLogin()
      }
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
