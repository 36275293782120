import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class DomainsDataService extends APIBaseFunctions {
  private cachedDomainsDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = DomainsDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedDomainsDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/domaenes?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/domaenes?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/domaenes/${id}`)
  }

  getCount () {
    return http.get('/domaenes/count')
  }

  create (data: any) {
    return http.post('/domaenes', data)
  }

  update (id: string, data: any) {
    return http.put(`/domaenes/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/domaenes/${id}`)
  }

  deleteAll () {
    return http.delete('/domaenes')
  }

  findByDomainStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let sortingOrderParameter = ''
    const currentDataSliceObj = DomainsDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedDomainsDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    return http.get(`/domaenes?domaene_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    let retVal
    let sortingOrderParameter = ''
    const currentDataSliceObj = DomainsDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedDomainsDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      sortingOrderParameter = `&_sort=${sortingOrder}`
    }

    if (ignoreStatus) {
      retVal = http.get(`/domaenes?_where[_or][0][domaene_titel_contains]=${searchTerm}&_where[_or][1][domaene_url_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    } else {
      retVal = http.get(`/domaenes?domaene_status=${status}&_where[_or][0][domaene_titel_contains]=${searchTerm}&_where[_or][1][domaene_url_contains]=${searchTerm}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return retVal
  }
}

export default new DomainsDataService()
