
import { Options, Vue } from 'vue-class-component'
import AdminMenus from '@/components/Menus/AdminMenus/index.vue' // @ is an alias to /src

@Options({
  components: {
    AdminMenus
  }
})
export default class MenusView extends Vue {}
